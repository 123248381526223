import React from 'react'
import { Layout } from '../templates'
import { Image } from '../components/Image'
import { jsx } from '@emotion/core'
import { CallToActions, CallToAction } from '../components'
import { TitleLarge, TitleMedium } from '../components/RichText/RichTextStyles'

const NotFoundPage = () => (
  <Layout>
    <div
      css={{ width: '70%', margin: '4rem auto 2rem auto', textAlign: 'center' }}
    >
      <Image
        data={{
          path: '404-img.svg',
          containerStyle: { margin: '2rem auto' },
          imgStyle: { margin: 'auto' },
        }}
      />
      <TitleMedium>404 Error</TitleMedium>
      <TitleLarge>Uh-oh — found yourself off path?</TitleLarge>

      <p>We recommend heading back to one of these places:</p>
      <CallToActions>
        <CallToAction
          data={{
            target: 'https://accolade.com',
            text: 'Homepage',
          }}
        />
        <CallToAction
          data={{ target: '/solutions', text: 'Accolade solutions' }}
        />
        <CallToAction data={{ target: '/demo', text: 'Schedule a demo' }} />
      </CallToActions>
    </div>
  </Layout>
)

export default NotFoundPage
